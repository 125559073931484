<template>
  <div class="crm f-flex">
    <div class="crm-name">開獎結果</div>
  </div>
  <div v-if="loading" class="loading"></div>
  <div class="table-con">
    <table class="m-table" v-if="currentGame.code < 20000">
      <tr>
        <th style="width: 120px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">开獎日期</th>
        <th style="width: 120px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">期數</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球1</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球2</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球3</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球4</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球5</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球6</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">特碼</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">總分</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">尾數量</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">一肖量</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">一肖</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">特碼 | 特合 | 總和 | 七色波</th>
      </tr>

      <tr v-for="(item, index) in records.data" :key="index">
        <td style="width: 120px">{{ item.date }}</td>
        <td style="width: 120px">{{ item.termNumber }}</td>
        <td
          :class="{
            'f-f-blue': filterNumberColor(items) == 'blue',
            'f-f-green': filterNumberColor(items) == 'green',
            'f-f-red': filterNumberColor(items) == 'red',
          }"
          style="width: 60px"
          v-for="(items, indexs) in splitResult(item.result)"
          :key="indexs"
        >
          {{ items }}
        </td>
        <td>{{ item.sumNums }}</td>
        <td>{{ item.weiNum }}</td>
        <td>{{ item.sxNum }}</td>
        <td>{{ item.sx }}</td>
        <td>
          {{ item.tmDxDs }} | {{ item.thDxDs }} | {{ item.zhDxDs }} |
          <span
            :class="{
              'f-f-blue': item.qsbo == '藍',
              'f-f-red': item.qsbo == '紅',
              'f-f-green': item.qsbo == '綠',
            }"
            >{{ item.qsbo }}</span
          >
        </td>
      </tr>
    </table>
    <table class="m-table" v-else>
      <tr>
        <th style="width: 120px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">开獎日期</th>
        <th style="width: 120px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">期數</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球1</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球2</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">球3</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">跨度</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">單雙</th>
        <th style="width: 60px;background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">大小</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">質合</th>
        <th style="background:rgba(0,0,0,0.02) !important; color:#000; font-weight: bold;">二字和單雙</th>
      </tr>

      <tr v-for="(item, index) in records.data" :key="index">
        <td style="width: 120px">{{ item.date }}</td>
        <td style="width: 120px">{{ item.termNumber }}</td>
        <td
          :class="{
            'f-f-blue': true,
          }"
          style="width: 60px"
          v-for="(items, indexs) in splitResult(item.result)"
          :key="indexs"
        >
          {{ items }}
        </td>
        <td>{{ item.kd }}</td>
        <td>{{ item.resultDs }}</td>
        <td>{{ item.resultDx }}</td>
        <td>{{ item.resultZh }}</td>
        <td>
          {{ item.twoNumDs }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick, computed, watch } from "vue";
import { balls } from "@/config/lhc.config";
import { useStore } from "vuex";
import find from "lodash.find";
import isUndefined from "lodash.isundefined";
export default {
  name: "LhcResult",
  components: {},
  setup() {
    const store = useStore();
    // 加载中
    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });
    watch(
      () => [currentGame.value.code],
      () => {
        requestGameSettleResult();
      }
    );
    const loading = ref(false);

    let records = reactive({
      data: [],
    });

    onMounted(() => {
      nextTick(() => {
        requestGameSettleResult();
      });
    });

    const splitResult = (result) => {
      if (result) {
        return result.split(",");
      } else {
        return [];
      }
    };
    const filterNumberColor = (num) => {
      if (num) {
        let obj = find(balls, (item) => {
          return item.ball == num;
        });
        if (isUndefined(obj)) {
          return "";
        }
        return obj.color;
      } else {
        return "";
      }
    };

    //開獎結果
    const requestGameSettleResult = () => {
      loading.value = true;
      store
        .dispatch("game/requestGameSettleResultAction", {
          gameCode: currentGame.value.code,
        })
        .then((res) => {
          loading.value = false;
          if (res && res.code == 0) {
            records.data = res.res;
          } else {
            records.data = [];
          }
        })
        .then(() => {
          emitClearBetEvent();
        })
        .catch(() => {
          loading.value = false;
        });
    };
    //格式化時间
    const formatTime = (time, format) => {
      return moment(time).format(format);
    };
    return {
      loading,
      records,
      currentGame,
      formatTime,
      requestGameSettleResult,
      splitResult,
      filterNumberColor,
    };
  },
};
</script>
